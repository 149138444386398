import React from "react"
import { graphql, Link  } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialLinks from "../constants/socialLinks"
// import { FaAutoprefixer } from "react-icons/fa"
// import {
//   FaStackOverflow,
//   FaLinkedinIn,
//   FaGithubSquare,
//   FaTwitter,
// } from "react-icons/fa"

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  // const iconSize = 20

  return (
    <Layout
      location={location}
      title={siteTitle}
      showSidebar={false}
      currPage={"About"}
    >
      <SEO title="About" />
      <div className="homepage-about-container">
        <Link to="/" className="homepage-about-link">Home</Link>
      </div>
        <Link to="/"><h1 className="index-header">James Shapiro</h1></Link>
      <h1
        className="index-header-about"
      >
        About
      </h1>
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={"James"}
        style={{
          minWidth: 50,
          borderRadius: `100%`,
          display: "block",
          margin: "0 auto",
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <div>
        <div
          style={{ 
            justifyContent: 'left', 
            width: '672px', 
            margin: '0 auto',
            marginTop: '40px',
          }}>
          <header
            className='about-page-text'
          >
            I am a software developer, cloud architect, and consultant, focused on
            cloud computing and experimenting with generative AI. I am interested in cloud security,
            scaling, automation, and serverless development.
          </header>

          <header
            className='about-page-text'
          >
            I run Athens Technologies, a technology consulting firm that specializes
            in cloud native applications. 
          </header>

          <header
            className='about-page-text'
          >
            I have an M.Sc. in Computer Science from Stanford, and
            a far more practical B.A. in Philosophy from Brown. Currently,
            I live in sunny Miami, FL.
          </header>
          <header
            className='about-page-emails'>
            Reach me at:
          </header>
          <header
            className='about-page-emails'  
          > 
          Business: my firstname.lastname at{" "}
            <a style={{ textDecoration: "none", color: "#9e0000" }} href="mailto:">
              gmail.com
            </a>{" "}
          </header>
          <header
            className='about-page-emails'  
          >Personal: my firstname at{" "}
            <a style={{ textDecoration: "none", color: "#9e0000" }} href="mailto:">
              athenstechnologies.com
            </a>
            .
          </header>
          
        </div>
        <div className="social-list-div">
          <SocialLinks />
        </div>
      </div>
      <div className="slanted-divider-about grey-dropshadow"></div>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
      childImageSharp {
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
