import React from "react"
import {
  FaStackOverflow,
  FaLinkedinIn,
  FaGithubSquare,
  FaTwitter,
} from "react-icons/fa"
import "../components/global.css"

const iconSize = 50

const data = [
  {
    id: 1,
    icon: (
      <FaStackOverflow
        className="social-icon"
        size={iconSize}
      ></FaStackOverflow>
    ),
    url: "https://stackoverflow.com/users/830841/james-shapiro?tab=profile",
  },
  {
    id: 2,
    icon: (
      <FaGithubSquare className="social-icon" size={iconSize}></FaGithubSquare>
    ),
    url: "https://github.com/jamesshapiro",
  },
  {
    id: 3,
    icon: <FaTwitter className="social-icon" size={iconSize}></FaTwitter>,
    url: "https://twitter.com/jamesshapiro",
  },
  // {
  //   id: 2,
  //   icon: <FaLinkedinIn className="social-icon" size={iconSize}></FaLinkedinIn>,
  //   url: "https://www.linkedin.com/in/james-shapiro-software/",
  // },
]
const links = data.map(link => {
  return (
    <li key={link.id} className="social-link">
      <a
        href={link.url}
        style={{ textDecoration: "none" }}
        className="social-link2"
      >
        {link.icon}
      </a>
      <span style={{ color: "#ffffff" }}>.....</span>
    </li>
  )
})

export default ({ styleClass }) => {
  return <ul className="social-links">{links}</ul>
}
